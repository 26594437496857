import { Component, computed, OnDestroy, OnInit, signal } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { ActivatedRoute } from '@angular/router';
import {
  CellEditingStoppedEvent,
  ColDef,
  GridApi,
  GridOptions,
  GridReadyEvent,
  IRowNode,
  RowSelectionOptions,
  SizeColumnsToContentStrategy,
} from 'ag-grid-community';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ISample } from '../../../samples/interface/sample.interface';
import { SamplesService } from '../../../samples/service/samples.service';
import { TowLogApiService } from '../../../tow-log/services/tow-log-api.service';
import { ICprUser } from '../../../users/interface/user.interface';
import { UsersService } from '../../../users/service/users.service';
import { IRecordingEvent } from '../../interface/recording-event.interface';
import { AnalystEditorComponent } from './analyst-editor.component';
import { AnalystRendererComponent } from './analyst-renderer.component';
import { PciColourEditorComponent } from './pci-colour-editor.component';
import { PciColourRendererComponent } from './pci-colour-renderer.component';

@Component({
  selector: 'app-tow-sample-allocation-screen',
  templateUrl: './tow-sample-allocation-screen.component.html',
  styleUrls: ['./tow-sample-allocation-screen.component.scss'],
})
export class TowSampleAllocationScreenComponent implements OnInit, OnDestroy {
  private readonly unsubscribe$ = new Subject<void>();

  tow: IRecordingEvent<'tow'>;
  rowData: Partial<ISample>[];
  analysts: ICprUser[];
  columnDefs: ColDef[];
  autoSizeStrategy: SizeColumnsToContentStrategy;

  readonly edit = signal(false);

  readonly gridOptions: GridOptions = {
    singleClickEdit: true,
  };

  readonly rowSelection = computed<RowSelectionOptions>(() => ({
    mode: 'multiRow',
    checkboxes: this.edit(),
    headerCheckbox: this.edit(),
    isRowSelectable: (node) => !node.data.finalisedAt,
  }));

  loadingData = true;

  readonly defaultColDef: ColDef = {
    sortable: false,
  };

  changesMade = false;
  permission = new Set<'allocate' | 'analyse'>();
  initialStringifiedValue: string;

  gridApi: GridApi<ISample>;

  constructor(
    private readonly fireAuth: AngularFireAuth,
    private readonly route: ActivatedRoute,
    private readonly sampleService: SamplesService,
    private readonly towLogs: TowLogApiService,
    private readonly userService: UsersService,
  ) {}

  ngOnInit() {
    this.fireAuth.user.pipe(takeUntil(this.unsubscribe$)).subscribe(async (user) => {
      const token = await user.getIdTokenResult();
      if (token.claims.context.permissions['sample']) {
        for (const scope of ['allocate', 'analyse', 'finalise']) {
          if (token.claims.context.permissions['sample'].includes(scope)) {
            this.permission.add(scope as 'allocate' | 'analyse');
          }
        }
      }
    });

    this.route.data.pipe(takeUntil(this.unsubscribe$)).subscribe(({ data }) => {
      this.tow = data;
      this.pull();
    });

    this.userService.analysts.then((analysts) => {
      if (analysts) {
        this.analysts = analysts;
        this.generateColumnDefs();
      }
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private pull() {
    this.sampleService
      .getPaginated('', { simpleFilters: { towId: `ObjectId:${this.tow._id}` } })
      .then((samples) => {
        this.initialStringifiedValue = JSON.stringify(samples.data);
        this.reset();
        this.loadingData = false;
      });
  }

  private generateColumnDefs() {
    this.columnDefs = [
      {
        field: 'sampleNo',
        headerName: 'Sample no.',
        sort: 'asc',
        cellClassRules: { finalised: (params) => !!params.data.finalisedAt },
      },
      {
        field: 'coordinates',
        cellRenderer: ({ value }) =>
          `${this.towLogs.getDegreesMinutesString(value[1], 'lat')}, ${this.towLogs.getDegreesMinutesString(value[0], 'lng')}`,
      },
      {
        field: 'sampleMidpointDate',
        headerName: 'Midpoint date',
        valueFormatter: ({ value }) => new Date(value).toLocaleString('en-GB'),
      },
      {
        field: 'chlorophyllIndex',
        headerName: 'PCI',
        width: 120,
        editable: (params) =>
          this.edit() && this.permission.has('analyse') && !params.data.finalisedAt,
        cellEditor: PciColourEditorComponent,
        onCellValueChanged: (params) => {
          params.data.isNotAnalyseable = params.newValue === null;
          setTimeout(() => params.api.deselectAll());
        },
        cellRenderer: PciColourRendererComponent,
      },
      {
        field: 'userId',
        headerName: 'Analyst',
        editable: (params) =>
          this.edit() && this.permission.has('allocate') && !params.data.finalisedAt,
        cellEditor: AnalystEditorComponent,
        onCellValueChanged: (params) => setTimeout(() => params.api.deselectAll()),
        cellRenderer: AnalystRendererComponent,
        cellRendererParams: (params) => {
          let i = Math.max(params.node.rowIndex - 4, 0);
          let max = Math.min(params.node.rowIndex + 4, this.rowData.length - 1);
          for (i; i <= max; i++) {
            if (params.node.rowIndex != i && this.rowData[i].userId === params.data.userId) {
              return { analystTooCloseToSelf: true };
            }
          }
          return { analystTooCloseToSelf: false };
        },
        valueFormatter: ({ value }) => {
          const analyst = this.analysts.find((user) => user._id === value);
          return `${analyst?.legacyAnalystId ?? 'N/A'}: ${analyst?.displayName ?? analyst?.name ?? 'N/A'}`;
        },
        flex: 1,
      },
    ];

    this.autoSizeStrategy = {
      type: 'fitCellContents',
      // Any cols without a size goal will autosize to cell contents
      colIds: this.columnDefs.filter((def) => !def.flex && !def.width).map(({ field }) => field),
    };
  }

  cancelEdit() {
    this.edit.set(false);
    this.generateColumnDefs();
    this.reset();
  }

  onCellEditingStopped(event: CellEditingStoppedEvent) {
    const selected = event.api.getSelectedNodes();
    if (selected.find((node) => node === event.node)) {
      for (const node of selected) {
        if (!node.data.finalisedAt) {
          node.setDataValue(event.column, event.newValue);
        }
      }
    }
    this.changesMade = JSON.stringify(this.rowData) !== this.initialStringifiedValue;
  }

  reset() {
    this.rowData = JSON.parse(this.initialStringifiedValue);
    this.changesMade = false;
  }

  async save() {
    const promises = [];
    const unfinalised = this.rowData.filter((sample) => !sample.finalisedAt);
    if (this.permission.has('analyse')) {
      const data = unfinalised.map(({ _id, chlorophyllIndex, isNotAnalyseable }) => ({
        _id,
        chlorophyllIndex,
        isNotAnalyseable,
      }));
      promises.push(this.sampleService.analyseMany(data));
    }
    if (this.permission.has('allocate')) {
      const data = unfinalised.map(({ _id, userId }) => ({ _id, userId }));
      promises.push(this.sampleService.allocateMany(data));
    }
    await Promise.allSettled(promises);
    this.initialStringifiedValue = JSON.stringify(this.rowData);
    this.cancelEdit();
  }

  startEdit() {
    this.edit.set(true);
    this.generateColumnDefs();
  }

  onGridReady(event: GridReadyEvent) {
    this.gridApi = event.api;
  }

  setSelection(pattern: 'odds' | 'evens' | '4ths') {
    this.gridApi.deselectAll();
    const nodes: IRowNode[] = [];
    switch (pattern) {
      case '4ths': {
        this.gridApi.forEachNode((node) => {
          if (node.selectable && node.rowIndex % 4 == 0) {
            nodes.push(node);
          }
        });
        break;
      }
      case 'evens': {
        this.gridApi.forEachNode((node) => {
          if (node.selectable && node.rowIndex % 2 == 1) {
            nodes.push(node);
          }
        });
        break;
      }
      case 'odds': {
        this.gridApi.forEachNode((node) => {
          if (node.selectable && node.rowIndex % 2 == 0) {
            nodes.push(node);
          }
        });
        break;
      }
    }
    this.gridApi.setNodesSelected({ nodes, newValue: true });
  }

  // RANDOM ALLOC DISABLED PRIOR TO UAT 2024-10-30

  // onAutoAllocate() {
  //   if (!this.permission.has('allocate')) {
  //     this.toast.error('Your account has not been granted permission to allocate.');
  //     return;
  //   }
  //
  //   const samples = this.gridApi
  //     .getSelectedRows()
  //     .filter((sample) => !sample.finalisedAt)
  //     .map((sample) => sample._id);
  //
  //   if (!samples?.length) {
  //     this.toast.error('You must make a selection first.');
  //     return;
  //   }
  //
  //   this.sampleService.autoAllocate(this.tow._id, samples, () => this.pull());
  // }
}
