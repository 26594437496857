import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FiltersChannel, IFilters, IItemsTableOptions, SessionService } from '@suvo-bi-lib';
import { take } from 'rxjs/operators';
import { TableComponent } from 'submodules/frontend-framework/SUVO-BI-CLIENT-LIB/projects/suvo-bi-client-lib/src/lib/features/tables/components/table/table.component';
import { ITablePagination } from 'submodules/frontend-framework/SUVO-BI-CLIENT-LIB/projects/suvo-bi-client-lib/src/lib/shared/interfaces/table/table-pagination.interface';
import { ITableSort } from 'submodules/frontend-framework/SUVO-BI-CLIENT-LIB/projects/suvo-bi-client-lib/src/lib/shared/interfaces/table/table-sort.interface';
import { ICuttingPoint } from '../../interface/cutting-point.interface';
import { IRecordingEvent } from '../../interface/recording-event.interface';
import { CuttingPointService } from '../../service/cutting-point.service';

@Component({
  template: `
    <app-header>
      <cpr-printout-menu />
    </app-header>

    <mat-card style="margin: 0 var(--global-padding)">
      <mat-card-header>
        <mat-card-title>Cutting points</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <table mat-table [dataSource]="dataSource$" matSort matSortDisableClear>
          <ng-container matColumnDef="sectionId">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              <span *ngIf="!isLoading">Section ID</span>
            </th>
            <td mat-cell *matCellDef="let item">{{ item.sectionId }}</td>
          </ng-container>

          <ng-container matColumnDef="cuttingPoint">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              <span *ngIf="!isLoading">Cutting point</span>
            </th>
            <td mat-cell *matCellDef="let item">{{ item.cuttingPoint }}</td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="headings"></tr>
          <tr
            mat-row
            class="row"
            [class.hidden]="isLoading"
            *matRowDef="let row; columns: headings"
          ></tr>

          <tr class="mat-row" *matNoDataRow [hidden]="isLoading">
            <td class="mat-cell" [attr.colspan]="headings.length">
              <span>No matching data.</span>
            </td>
          </tr>
        </table>

        @if (isLoading) {
          <suvo-bi-skeleton-table-rows [rowCount]="pageSize" [columnWidths]="widths" />
        }

        <mat-paginator
          [pageSize]="pageSize"
          [pageSizeOptions]="pageSizeOptions"
          showFirstLastButtons
        />
      </mat-card-content>
    </mat-card>
  `,
})
export class TowCuttingPointsScreenComponent extends TableComponent<ICuttingPoint, string> {
  readonly headings = ['sectionId', 'cuttingPoint'];
  readonly widths = [50, 50];

  @Input() filtersChannel?: FiltersChannel;
  @Input() options: IItemsTableOptions;
  @Input() dontCache = false;
  @Input() tableAlias: string = 'cutting-points';

  tow: IRecordingEvent<'tow'>;

  pageSize = this.options?.defaultPageSize || 15;
  canSort = false;
  defaultSort = { active: 'sectionId', direction: 'asc' };

  constructor(
    route: ActivatedRoute,
    sessionService: SessionService,
    private readonly cuttingPoints: CuttingPointService,
  ) {
    super(sessionService);
    route.data.pipe(take(1)).subscribe(({ data }) => (this.tow = data));
  }

  protected getPaginated(
    filters: IFilters,
    sort: ITableSort,
    pagination: ITablePagination,
    search: string,
  ) {
    return this.cuttingPoints.getPaginated('', {
      filters,
      sort,
      pagination,
      search,
      simpleFilters: { towId: `ObjectId:${this.tow._id}` },
    });
  }
}
